<template>
    <b-modal :id="'fulcrum-issue-modal-' + parentID" v-model="currentlyShowing" size="xl" title="Issue Record" hide-footer>
        <b-form v-on:submit.prevent>
            <b-row v-if="!sending && !sent && !awaitingCheckIfIssued && !errorDurringSend">
                <b-col sm="12" :xl="(issuedData.length > 0) ? '6': '12'">
                    <b-row>
                        <b-col>
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-app-name-label-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'Select App List:'"
                                :label-for="'fulcrum-issue-modal-select-app-name-' + id"
                            >

                                <b-form-input
                                    :id="'fulcrum-issue-modal-select-app-name-' + id"
                                    v-model="appSelected"
                                    :list="'fulcrum-app-datalist-' + id"
                                    :required="true"
                                    :multiple="false"
                                    :state="appSelectionValid"
                                    @input="touchApp($event)"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-datalist :id="'fulcrum-app-datalist-' + id" :options="formNamesForDataList"></b-form-datalist> 
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-product-code-label-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'Select Product Code:'"
                                :label-for="'fulcrum-issue-modal-select-product-code-' + id"
                            >

                                <b-form-input
                                    :id="'fulcrum-issue-modal-select-product-code-' + id"
                                    v-model="productCodeSelected"
                                    :list="'fulcrum-issue-modal-product-code-datalist-' + id"
                                    :required="true"
                                    :multiple="false"
                                    :state="productSelectionValid"
                                    @input="touchProductCode($event)"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-datalist :id="'fulcrum-issue-modal-product-code-datalist-' + id" :options="productCodeOptions"></b-form-datalist>
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-status-label-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'Select Status:'"
                            >
                            
                                <b-form-radio v-for="(status, index) in statusOptions" :key="index"
                                    :id="'fulcrum-issue-modal-select-status-' + index + '-' + id"
                                    v-model="statusSelected"
                                    name="select-status"
                                    :required="true"
                                    :state="statusSelectionValid"
                                    :value="status.value"
                                    @input="touchStatus($event)"
                                >
                                    <div :style="'color:' + status.textColor + '; background-color: ' + status.bgColor + ';'">
                                        {{status.label}}
                                    </div>
                                </b-form-radio>
                            </b-form-group >
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-tasks-label-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'Select Job Tasks:'"
                                :label-for="'fulcrum-issue-modal-select-tasks-' + id"
                            >
                            
                                <b-form-checkbox
                                    :id="'fulcrum-issue-modal-select-tasks-test-' + id"
                                    v-model="tasks.bfpaTest"
                                    name="bfpaTest"
                                    :required="false"
                                    :value="true"
                                >
                                    BFPA Test
                                </b-form-checkbox>
                                <b-form-checkbox
                                    :id="'fulcrum-issue-modal-select-tasks-survey-' + id"
                                    v-model="tasks.survey"
                                    name="survey"
                                    :required="false"
                                    :value="true"
                                >
                                    Survey
                                </b-form-checkbox>
                                <b-form-checkbox
                                    :id="'fulcrum-issue-modal-select-tasks-replacement-' + id"
                                    v-model="tasks.replacement"
                                    name="replacement"
                                    :required="false"
                                    :value="true"
                                >
                                    Replacement
                                </b-form-checkbox>
                                <b-form-checkbox
                                    v-if="$store.getters.allUserInfo.account == '3ae9e598-d536-4a12-a892-102a70b7d38f'"
                                    :id="'fulcrum-issue-modal-select-tasks-sanitary-survey-' + id"
                                    v-model="tasks.sanitarySurvey"
                                    name="sanitarysurvey"
                                    :required="false"
                                    :value="true"
                                >
                                    Sanitary Survey
                                </b-form-checkbox>
                            </b-form-group >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-if="issuedData.length > 0" sm="12" xl="6">
                    <b-row>
                        <b-col>
                            <div v-if="numberOfOutstandingIssued == 0">No Outstanding Records In The Field. Has Been Issued To The Field {{issuedData.length}} {{(issuedData.length > 1) ? 'Times': 'Time' }}.</div>
                            <div v-else>Has {{numberOfOutstandingIssued}} Outstanding {{(numberOfOutstandingIssued > 1) ? 'Records': 'Record' }}</div>
                            <b-button size="sm" v-if="numberOfOutstandingIssued == 0 && issuedData.length > 0" @click="toggleShowHistory">{{(showHistory) ? 'Hide Field History' : 'Show Field History'}}</b-button>
                        </b-col>
                        <b-col v-if="showHistory" sm="12">
                            <span>Field Work History:</span>
                            <b-list-group>
                                <b-list-group-item v-for="(iss, index) in issuedData" :key="'issued_' + index" :variant="(iss.disabled) ? 'dark': (iss.completed) ? 'success': 'warning'">
                                    <b-row>
                                        <b-col sm="12" lg="6">
                                            <div v-if="!iss.completed"><b>Currently Outstanding</b></div>
                                            <div v-else-if="iss.completed"><b>Completed</b></div>
                                        </b-col>
                                        <b-col sm="12" lg="6">
                                            <div>Issued To Fulcrum On:</div>
                                            <div><em>{{iss.issuedOn}}</em></div>
                                            <b-button size="sm" :href="'https://web.fulcrumapp.com/records/' + iss.fulcrumID" target="_blank">View In App</b-button>
                                            <b-button size="sm" :href="'fulcrumapp://edit-record?record_id=' + iss.fulcrumID">Open in App (Mobile)</b-button>
                                        </b-col>
                                        <b-col v-if="iss.events.length > 0" sm="12">
                                            <b-list-group >
                                                <b-list-group-item v-for="(event, taskIdx) in iss.events" :key="'event_' + taskIdx">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <div><em>{{event.eventName}}</em></div>
                                                            <div><em>{{event.eventTstamp}}</em></div>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <b-button size="sm" v-if="event.eventName != 'Replacement'" :href="(event.eventName == 'BFPA Test') ? '/home/test/' + event.eventID : (event.eventName == 'Survey') ? '/home/survey/': ''" target="_blank">View Test</b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-col>
                                    </b-row>
                                    
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row v-else-if="sending && !sent && !errorDurringSend">
                <b-col sm="12">
                    <div class="text-center">
                        <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="The Magic Of The Internet"></b-spinner>
                    </div>
                </b-col>
                <b-col sm="12">
                    <div class="text-center" style="font-size: 1.5em;">
                        Hang Tight While We Send It Over
                    </div>
                </b-col>
            </b-row>
            <b-row v-else-if="sent  && !errorDurringSend">
                <b-col sm="12">
                    <div class="text-center" style="font-size: 1.5em;">
                        <b-link :href="fulcrumLink" target="_blank">Click Here To View The Record In App (Opens In A New Tab)</b-link>
                    </div>
                </b-col>
                <b-col sm="12">
                    <div class="text-center">
                        <b-button variant="success" @click="hideSelf">Done</b-button>
                    </div>
                </b-col>
                
            </b-row>
            <b-row v-else-if="awaitingCheckIfIssued">
                <b-col sm="12">
                    <div class="text-center">
                        <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="The Magic Of The Internet"></b-spinner>
                    </div>
                </b-col>
                <b-col sm="12">
                    <div class="text-center" style="font-size: 1.5em;">
                        Fetching Issued History
                    </div>
                </b-col>
            </b-row>
            <b-row v-else-if="errorDurringSend">
                <b-col sm="12">
                    <div class="text-center" style="font-size: 1.5em;">
                        An Error Occured While Sending This Record To Field App
                    </div>
                </b-col>
            </b-row>
        </b-form>
        <b-button-toolbar v-if="!sending && !sent" justify>
            <b-button variant="warning" @click="hideSelf">Exit Without Issuing Record</b-button>
            <b-button variant="success" :disabled="!readyToIssue" @click="issueRecord">Issue Record</b-button>
        </b-button-toolbar>
        
    </b-modal>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

export default {
    name: 'issuetofulcrummodal',
    props:{
        parentID: {
            type: String,
            default: () => {return null} 
        },
        deviceID: {
            type: String,
            default: () => {return null} 
        }
    },
    data(){
        return{
            id: null,
            currentlyShowing: false,
            awaitingCheckIfIssued: false,
            checkIssuedCompletedWithoutError: false,
            rawIssuedData: [],
            issuedData: [],
            numberOfOutstandingIssued: 0,
            showHistory: false,
            sending: false,
            sent: false,
            errorDurringSend: false,
            forms: [],
            formNamesForDataList: [],
            appSelectionValid: null,
            statusSelectionValid: null,
            requiresUpdate: false,
            lastSelection: null,
            appSelected: null,
            appIDSelected: null,
            statusSelected: null,
            statusOptions: [],
            productCodeOptions: [],
            productCodeSelected: null,
            productSelectionValid: null,
            tasks: {
                bfpaTest: false,
                survey: false,
                replacement: false,
                sanitarySurvey: false
            },
            readyToIssue: false,
            fulcrumLink: 'https://google.com'
        }
    },
    methods: {
        issueRecord(){
            this.sending = true;

            var issueRequestPacket = {
                deviceID: this.deviceID,
                formID: this.appIDSelected,
                status: this.statusSelected,
                productCode: this.productCodeSelected,
            };

            if(this.tasks.bfpaTest || this.tasks.survey || this.tasks.replacement || this.tasks.sanitarySurvey){
                var tasks = [];
                if(this.tasks.bfpaTest){
                    tasks.push("BFPA Test");
                }
                if(this.tasks.survey){
                    tasks.push("Survey");
                }
                if(this.tasks.replacement){
                    tasks.push("BFPA Replacement");
                }
                if(this.tasks.sanitarySurvey){
                    tasks.push("Sanitary Survey");
                }
                issueRequestPacket.selectJobTasks = tasks;
            }

            instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/fulcrum/issue.json", issueRequestPacket)
            .then((response)=>{
                this.sending = false;
                this.sent = true;
                var result = response.data.result;
                this.fulcrumLink = result.fulcrumRecordURL;
            })
            .catch((err)=>{
                this.sending = false;
                this.sent = true;
                this.errorDurringSend = true;
                // TODO: An Error Has OCcured Do Something Useful With it
                console.log(err)
            })
        },
        hideSelf(){
            this.$bvModal.hide('fulcrum-issue-modal-' + this.parentID);
        },
        fetchFormData(){
            this.fetchProductCodeOptions();
            var storedForms = this.$store.getters.getFulcrumFormStore;
            if(storedForms.lastFetched == null){
                this.requiresUpdate = true;
                console.log(`Fulcrum Apps Never Fetched`)
            }else if(Math.floor((new Date() - storedForms.lastFetched) / (1000*60*60*6)) > 0){
                // Last Fetched More Than 6 Hours Ago
                console.log(`Fulcrum Apps Last Fetched ${storedForms.lastFetched}`)
                this.requiresUpdate = true;
            }

            if(this.requiresUpdate){
                instance.get(process.env.VUE_APP_API_BASE_URL + "/atils/fulcrum/apps.json")
                .then((response)=>{
                    // console.log(response.data.result);
                    this.$store.commit('updateFulcrumForms', {forms: response.data.result});
                    var formCheck = this.$store.getters.getFulcrumFormStore;
                    console.log(formCheck);
                    this.forms = response.data.result;
                    this.requiresUpdate = false;
                    this.statusSelected = '_|AUTO|_';
                    this.parseForms();
                })
                .catch((err)=>{
                    console.log("An Error Occured While Fetching Fulcrum Forms")
                    // TODO: Handle Errors Here
                })
            }else{
                this.forms = storedForms.forms;
                this.appSelected = storedForms.lastSelectedForm;
                this.statusSelected = storedForms.lastSelectedStatus;
                this.touchApp();
                this.touchStatus();
                this.parseForms();
                this.updateReadyToIssue();
            }
        },
        parseForms(){
            this.forms.forEach((f)=>{
                // var matchesBlacklist = f.name.match(/(design|template|sandbox|timesheet|vehicle)/gi);
                var matchesBlacklist = f.name.match(/(design|template|timesheet|vehicle)/gi);
                if(matchesBlacklist == null && _.keys(f.status).length != 0){
                    this.formNamesForDataList.push(f.name);
                }
            })
        },
        touchApp() {
            // Check if the appSelectedUpdated
            if(this.lastSelection != this.appSelected){
                // A Change Occured
                this.statusOptions = [];
                this.lastSelection = cloneDeep(this.appSelected);
                var selectedApp = this.forms.filter((form)=>{ return form.name == this.appSelected; });
                if(selectedApp.length == 1){
                    // Perform Status Selection
                    this.appSelectionValid = true;
                    this.appIDSelected = selectedApp[0].id;
                    var sOptKeys = _.keys(selectedApp[0].status);
                    let automagic = {bgColor: "#99ff33", textColor: "#000000", label: "Automatic Status Selection", value: '_|AUTO|_'};
                    this.statusOptions.push(automagic);
                    sOptKeys.forEach((statusOpt)=>{
                        let bgColorValue = this.hexToRgb(selectedApp[0].status[statusOpt].color);
                        let textColor = null;
                        if ((bgColorValue.r*0.299 + bgColorValue.g*0.587 + bgColorValue.b*0.114) > 186){
                            // Use Dark Text
                            textColor = "#000000";
                        }else{
                            // Use Light Text
                            textColor = "#ffffff";
                        }
                        let altOpt = {bgColor: selectedApp[0].status[statusOpt].color, textColor: textColor, label: selectedApp[0].status[statusOpt].label, value: selectedApp[0].status[statusOpt].value};
                        this.statusOptions.push(altOpt);
                        this.touchStatus();
                    })
                    
                }else{
                    // Invalid Selection
                    this.appSelectionValid = false;
                    this.statusOptions = [];
                    this.statusSelected = null;
                    this.updateReadyToIssue();
                }
            }else{
                // No Change Occured
                this.updateReadyToIssue();
            }
        },
        touchStatus(){
            let validStatus = this.statusOptions.filter((status)=>{ return status.value == this.statusSelected; })
            if(validStatus.length == 1){
                // Is Valid
                this.statusSelectionValid = true;
                this.$store.commit('setFulcrumFormsStoreAccess', {lastSelectedForm: this.appSelected, lastSelectedStatus: this.statusSelected});
                this.updateReadyToIssue();
            }else{
                // Not Valid
                this.statusSelectionValid = false;
                this.updateReadyToIssue();
            }
        },
        touchProductCode(){
            let validProductCode = this.productCodeOptions.filter((pcode)=>{ return pcode.value == this.productCodeSelected; })
            if(validProductCode.length == 1){
                // Is Valid
                this.productSelectionValid = true;
                this.updateReadyToIssue();
            }else{
                // Not Valid
                this.productSelectionValid = false;
                this.updateReadyToIssue();
            }
        },
        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },
        updateReadyToIssue(){
            if(this.appSelectionValid && this.statusSelectionValid && this.productSelectionValid){
                this.readyToIssue = true;
            }else{
                this.false = true;
            }
        },
        checkIfIssued(){
            this.awaitingCheckIfIssued = true;
            this.checkIssuedCompletedWithoutError = false;
            instance.get(process.env.VUE_APP_API_BASE_URL + `/atils/fulcrum/issued/${this.deviceID}.json`)
            .then((response)=>{
                this.rawIssuedData = response.data.result;
                this.issuedDataParser();
            })
            .catch((err)=>{
                this.awaitingCheckIfIssued = false;
                this.checkIssuedCompletedWithoutError = false;
                // TODO: An Error Has OCcured Do Something Useful With it
                console.log(err)
            })
        },
        issuedDataParser(){
            this.rawIssuedData.forEach((issue)=>{
                var tmpItem = {
                    fulcrumID: issue.fulcrum_id,
                    appID: issue.app_id,
                    issuedOn: this.formatDate(issue.issued_on),
                    deviceID: issue.device_id,
                    connectionID: issue.connection_id,
                    disabled: issue.disabled,
                    completed: false,
                    events: []
                };
                issue.wh_import_ids.forEach((impID, index)=>{
                    if(impID != null){
                        tmpItem.completed = true;
                        var tmpEvent = {
                            importID: impID,
                            eventID: issue.import_event_ids[index],
                            eventName: issue.import_event_names[index],
                            refID: issue.ref_ids[index],
                            imported: issue.import_dates[index],
                            eventTstamp: this.formatDate(issue.event_dates[index]),
                        };
                        tmpItem.events.push(tmpEvent)
                    }
                })
                this.issuedData.push(tmpItem);
                if(tmpItem.completed == false){
                    this.numberOfOutstandingIssued++;
                }
            })
            if(this.numberOfOutstandingIssued != 0){
                this.showHistory = true;
            }
            this.checkIssuedCompletedWithoutError = true;
            this.awaitingCheckIfIssued = false;
        },
        formatDate(dateVal){
            var workingVal = null;
            var output = "";
            if(_.isDate(dateVal)){
                workingVal = dateVal;
            }else{
                workingVal = new Date(dateVal);
            }
            output = output + workingVal.getFullYear() + "-" + (("0" + (workingVal.getMonth() + 1)).slice(-2)) + "-" + (("0" + workingVal.getDate()).slice(-2)) + " ";
            output = output + (("0" + workingVal.getHours()).slice(-2)) + ":" + (("0" + workingVal.getMinutes()).slice(-2)) + ":" + (("0" + workingVal.getSeconds()).slice(-2));
            return output;
        },
        fetchProductCodeOptions(){
            instance.get(process.env.VUE_APP_API_BASE_URL + '/choices/product_codes.json?choices=true')
            .then(async (response) => {
                this.productCodeOptions = response.data.result.records;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        toggleShowHistory(){
            this.showHistory = !this.showHistory;
        }
    },
    computed: {
        
    },
    watch:{
        currentlyShowing: function(currentState, previousState){
            // console.log(`Modal Visibility Went From: ${previousState} to ${currentState}`)
            if(!previousState && currentState){
                // When Opening From Closed, Check if This Record Has Been Issued
                // Clear State
                this.sending = false;
                this.sent = false;
                this.rawIssuedData = [];
                this.issuedData = [];
                this.numberOfOutstandingIssued = 0;
                this.errorDurringSend = false;
                this.showHistory = false;
                // Check if Issued
                this.checkIfIssued();
            }
        }
    },
    mounted(){
        this.fetchFormData();
    },
    created(){
    },
    beforeDestroy(){
        
    },
    beforeMount (){
        this.id = uuidv4();
    },
}
</script>

<style scoped>

</style>


