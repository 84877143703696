<template>
    <div class="stage-inner-container-padded">
        <b-overlay :show="isSubmitting" rounded="sm">
            <template v-slot:overlay>
                <div class="text-center">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    <p>The Carrier Pidgeons Have Been Dispatched</p>
                </div>
            </template>
            <div v-if="!submissionCompleted">
                <b-row>
                    <b-col cols="12" md="6">
                        <h2>Create A New Customer</h2>
                    </b-col>
                    <!-- <b-col cols="12" md="6">
                        <span class="d-sm-none d-none d-md-block text-right">
                            <b-button variant="warning" @click="$router.go(-1)">Discard Changes and Exit</b-button>
                        </span>
                        <span class="d-block d-sm-block d-md-none">
                            <b-button variant="warning" @click="$router.go(-1)">Discard Changes and Exit</b-button>
                        </span>
                    </b-col> -->
                    <b-col cols="12">
                        <hr>
                    </b-col>
                    <b-col cols="12">
                        <!-- Main Content -->
                        <b-form v-on:submit.prevent>
                            <b-row>
                                <b-col cols="12" md="6">
                                    <text-input
                                        dataName="first_name"
                                        inputLabel="Billing Contact First Name"
                                        :parentFormID="localFormID"
                                        :required="true"
                                    ></text-input>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <text-input
                                        dataName="last_name"
                                        inputLabel="Billing Contact Last Name"
                                        :parentFormID="localFormID"
                                        :required="true"
                                    ></text-input>
                                </b-col>
                            </b-row>
                            <text-input
                                dataName="company"
                                inputLabel="Billing Company"
                                :parentFormID="localFormID"
                                :required="false"
                            ></text-input>
                            <array-input
                                :parentFormID="localFormID"
                                dataName="phone_number"
                                inputLabel="Billing Contact Phone Number"
                                componentName="phone-input"
                            ></array-input>
                            <array-input
                                :parentFormID="localFormID"
                                dataName="email"
                                inputLabel="Billing Contact Email"
                                componentName="email-input"
                            ></array-input>
                            <address-input
                                :parentFormID="localFormID"
                                dataName="billing_address"
                                inputLabel="Billing Contact Address"
                                :value="customerAddressDefault"
                            ></address-input>
                            <text-area
                                dataName="notes"
                                inputLabel="Customer Notes"
                                :parentFormID="localFormID"
                                :required="false"
                            ></text-area>
                            <b-button-toolbar justify>
                                <b-button variant="warning" @click="$router.go(-1)">Discard Changes and Exit</b-button>
                                <b-button variant="success" @click="attemptSend">Save Changes</b-button>
                            </b-button-toolbar>
                        </b-form>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <b-row align-h="center">
                    <b-col cols="12" md="6" align-self="center">
                        <div class="d-flex justify-content-center">
                            <h3>Added Customer</h3>
                        </div>
                        <b-button-toolbar justify>
                            <b-button variant="warning" @click="$router.go(-1)">Back</b-button>
                            <b-button variant="success" @click="goToNewlyCreated">View New Customer</b-button>
                        </b-button-toolbar>
                    </b-col>
                </b-row>
            </div>
        </b-overlay>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

// Form Components
import RegexText from '@/components/FormTextRegexInput.vue'
import TextArea from '@/components/FormTextAreaInput.vue'
import PassedSelect from '@/components/FormSelectPassedValuesInput.vue'
import APISelect from '@/components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '@/components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '@/components/FormDecimalInput.vue'
import IntegerInput from '@/components/FormIntegerInput.vue'
import GPSInput from '@/components/FormGPSInput.vue'
import PhoneInput from '@/components/FormPhoneInput.vue'
import EmailInput from '@/components/FormEmailInput.vue'
import FormArrayInput from '@/components/FormArrayEngine.vue'
import RadioInput from '@/components/FormRadioInput.vue'
import BoolInput from '@/components/FormBooleanInput.vue'
import AddressInput from '@/components/FormAddressComponent.vue'
import NestedForm from '@/components/NestedChildrenForms.vue'
import EventList from '@/components/FormEventListHistory.vue'
import ParentSelect from '@/components/FormParentSelection.vue'
import IssueFulcrum from '@/components/subcomponents/IssueToFulcrumModal.vue'

export default {
    name: 'createcustomer',
    components: {
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        'gps-input': GPSInput,
        'phone-input': PhoneInput,
        'email-input': EmailInput,
        'array-input': FormArrayInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput,
        'address-input': AddressInput,
        'nested-child-forms': NestedForm,
        'event-list': EventList,
        'issue-to-fulcrum-modal': IssueFulcrum,
        'parent-api-select': ParentSelect
    },
    props:{
        localFormID: {
            type: String,
            default: () =>{ return uuidv4(); }
        }
    },
    data(){
        return{
            isSubmitting: false,
            submissionCompleted: false,
            newCustomerID: null,
            customerAddressDefault: {
                dataNamePrefix: "billing_address",
                number: null,
                predirectional: null,
                name: null,
                suffix: null,
                postdirectional: null,
                unit: null,
                building: null,
                extra: null,
                city: null,
                state: null,
                zip: null,
                zipPlusFour: null,
                zipPlusTwo: null,
                lineTwo: null
            }
        }
    },
    methods: {
        navigateTo(path){
            this.$router.push({path: path})
        },
        attemptSend(){
            // Fetch Form From Vue
            var form = this.$store.getters.getForm(this.localFormID);
            // Get Form Validity
             var x = this.checkFormForValiditiy(form);
            if(x.valid){
                // Orient Form So Its Ready To Send To The API
                var sendable = {};
                // Remove Meta From Consideration
                var valid = _.omit(form, 'meta');
                // Get Keys
                var keys = _.keys(valid);
                
                keys.forEach((key)=>{
                    if(valid[key].type == "formarrayengine"){
                        var arrayVal = [];
                        var children = _.keys(valid[key].children);
                        children.forEach((childKey, index)=>{
                            arrayVal.push(valid[key].children[childKey].value);
                        })
                        sendable[key] = arrayVal;
                    }else{
                        if(valid[key].value != undefined){
                            sendable[key] = valid[key].value
                        }
                    }
                })
                console.log(sendable);
                this.isSubmitting = true;
                instance.post(process.env.VUE_APP_API_BASE_URL + '/customers.json', sendable)
                    .then(async (response) => {
                        this.newCustomerID = response.data.result.customer_id;
                        this.isSubmitting = false;
                        this.submissionCompleted = true;
                    })
                    .catch((error) => {
                        this.isSubmitting = false;
                        console.log(error);
                        console.log(error.response)
                        this.makeToast("Failed To Save Customer", `Something Went Wrong Durring The Request`, "danger", 30000);
                    });
            }else{
                // Complain About This To The User
                console.log(x);
                var failKeys = _.keys(x.failures);
                failKeys.forEach((failure)=>{
                    var val = this.transformToProperCase(failure);
                    this.makeToast("Cannot Save Form - Form Invalid", `${val} is invalid`, "danger", 5000);
                })
            }
        },
        checkFormForValiditiy(form){
            // Remove Meta From Consideration
            var valid = _.omit(form, 'meta');
            // Get Keys
            var keys = _.keys(valid);
            // Check Each Key
            var allValidations = {};
            keys.forEach((key)=>{
                if(valid[key].type == "formarrayengine"){
                    var children = _.keys(valid[key].children);
                    children.forEach((childKey, index)=>{
                        allValidations[key + '-_-' + index] = valid[key].children[childKey].valid;
                    })
                }else{
                    allValidations[key] = valid[key].valid;
                }
            });
            // Omit Passing Keys
            var failures = _.omit(allValidations, (val, key, obj) => {
                return !!(val);
            });
            var valid;
            if(_.keys(failures).length > 0){
                valid = false;
            }else{
                valid = true;
            }
            return {
                valid,
                failures
            };
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, delay = 3000){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        transformToProperCase(val){
            var tmp = val.replace(/_/g, " ");
            return this.capitalizeEachWord(tmp);
        },
        capitalizeEachWord(str) {
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        goToNewlyCreated(){
            var thisFormType = "customers";
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                this.$router.push({path: '/home/form/' + defaultFormForType[0].id + '/' + this.newCustomerID})
            }
        }
    },
    computed: {
        
    },
    mounted(){
        
    },
    created(){
    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.localFormID});
    },
    beforeMount (){
        // Create A Form In Vuex For This New Record
        this.$store.commit('initForm',{ localFormID: this.localFormID, meta: {} });
    },
}
</script>

<style scoped>
</style>

