<template>
    <b-row class="mb-3">
        <b-col sm="4" lg="3">
            {{inputLabel}}
        </b-col>
        <b-col sm="8" lg="9">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <b-overlay :show="searching" rounded="sm">
                        <b-input-group>
                            <b-form-input 
                                id="parent-search-input" 
                                placeholder="Enter Search Term Here" 
                                v-model="searchTerm"
                                @keyup.enter.native="search()"
                                :state="validationResult.all"
                            ></b-form-input>
                            <template v-slot:append>
                                <b-button variant="info" @click="search()" class='material-icons modal-btn' :disabled="searching">search</b-button>
                            </template>
                        </b-input-group>
                        </b-overlay>
                    </b-col>
                    
                    <b-col cols="12">
                        <hr>
                    </b-col>
                    <b-col cols="12">
                         <b-row
                            v-for="(result, index) in options" :key="result.id" 
                            :class="(selectedIndex == index ) ? 'hoverable' + ' selected-parent' : 'hoverable'"
                            @click="selectParent(result.id, index)"
                        >
                            <b-col cols="1" lg="1">
                                <b>{{index}}</b>
                            </b-col>
                            <b-col cols="11" lg="5">
                                <b>{{result.match_name}}</b>
                            </b-col>
                            <b-col cols="6" lg="4">
                                <b>{{result.matched_on}}</b>
                            </b-col>
                            <b-col cols="6" lg="2">
                                <b>{{result.txt}}</b>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
const axios = require('axios');
const _ = require('underscore');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
import MicroArrayAddButton from '@/components/microcomponents/ArrayItemAddButton.vue'
import MicroArrayRemoveButton from '@/components/microcomponents/ArrayItemRemoveButton.vue'

export default {
    name: 'formselectparent',
    components:{
        'btn-add': MicroArrayAddButton,
        'btn-remove': MicroArrayRemoveButton
    },
    props:{
        dataName: String,
        inputLabel: String,
        parentType: String,
        parentFormID: String,
        multiSelect: {
            type: Boolean,
            default: () => {return false}
        },
        value: {
            type: [String, Array],
            default: (val) => {
                if(typeof val == "string")
                    return "";
                else
                    return [];
                }
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        displayCount: {
            type: Number,
            default: () => {return 1;} 
        },
        change: {
            type: Function,
            default: () => {return (val)=>{ console.log("No Change Function Set For Parent Selection Input");}}
        }
    },
    data(){
        return{
            searchTerm: null,
            searching: false,
            limit: 25,
            offset: 0,
            selectedIndex: -1,
            inputValue: null,
            originalValue: null,
            options: [],
            isDirty: false,
            count: 1,
            isRed: false,
        }
    },
    methods: {
        touch() {
            this.isDirty = true;
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$emit('onTouch', this.dataName);
            this.updateRed();
        },
        search: _.debounce(function(){
            console.log(`Search Requested For: ${this.parentType} Term: ${this.searchTerm}`);
            this.searching = true;
            var encodedSearchTerm = encodeURIComponent(this.searchTerm);
            instance.get(process.env.VUE_APP_API_BASE_URL + '/search/term/' + encodedSearchTerm + '?parent=' + this.parentType + `&offset=${this.offset}&limit=${this.limit}`)
            .then(async (response) => {
                this.searching = false; 
                this.options = response.data.result.records;
                console.log(this.options);
                this.updateRed();
            })
            .catch((error) => {
                console.log(error);
                this.searching = false; 
            });
        }, 500),
        selectParent(parentID, indexOfItem){
            this.selectedIndex = indexOfItem;
            this.inputValue = parentID;
            this.change(parentID);
            this.touch();
        },
        updateRed(){
            if(this.validationResult.all){
                this.isRed = false;
            }else{
                this.isRed = true;
            }
        }
    },
    computed: {
        // a computed getter
        validationResult: function () {
            var results = this.options.filter(elm => elm.id == this.inputValue);
            var match = true;
            if( (results == null || results.length == 0) && this.required)
                match = false;
            // var regexRes = ( (!this.required) ? this.validator.test(this.inputValue) || this.inputValue.length == 0 : this.validator.test(this.inputValue) );
            var requiredRes = ( ((this.required && this.inputValue == null) || (this.required && this.inputValue.length == 0)) ? false : true) ;

            return {matchOptions: match, requirementFilled: requiredRes, all: (match && requiredRes)};
        },
        invalidFeedback(){
            var res = this.validationResult;
            if(res.all)
                return ''
            else if(!res.requirementFilled)
                return 'Required'
            else if(!res.matchOptions)
                return 'Invalid Entry: Not One Of The Available Options'
            else
                return 'OK'
        }
    },
    mounted(){
        //console.log(this.validationResult.all);
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all} );
    },
    created(){
    },
    beforeDestroy(){
        if(this.index == -1){
            this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
        }
    },
    beforeMount (){
        if(this.multiSelect){
            if(this.displayCount == 1){
                this.count = 4;
            }else{
                this.count = this.displayCount;
            }
        }
        this.originalValue = cloneDeep(this.value);
        this.inputValue = this.value;
        // FETCH API
        
    },
}
</script>

<style scoped>
.hoverable:hover{
    background-color: lightgreen;
}
.hoverable{
    cursor:pointer;
    padding: 0.2em;
}
.selected-parent{
    background-color: lightblue;
}
.selected-parent:hover{
    background-color: lightblue;
}
.red{
    border-color: red;
    border-width: 1px;
}
</style>


